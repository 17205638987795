import React from 'react';
import styled from 'styled-components';
import SocialShare from './SocialShare';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link

const StylesWrapper = styled.section`
  padding-top: ${(props) => props.paddingTopMobile || '60px'};
  @media (min-width: 992px) {
    padding-top: ${(props) => props.paddingTop || '60px'};
  }
  background: ${(props) => props.background === "light" ? '#e2e8ff !important' : ''};
  padding-bottom: ${(props) => props.paddingBottom || '60px'};
  border-bottom: ${(props) => props.border ? '10px solid #FF6C37' : ''};
  h2 {
    font-size: 55px;
    line-height: 1.1;
  }
  .h3 {
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
  }
  .eyebrow {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
  }
  p {
    color: ${(props) => props.background === "light" ? '#172144 !important' : '#ffffff'};
  }

  .subtitle {
    color: white;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: 10px;
  }

  .title-gradient {
    font-size: 55px;
    line-height: 1.1;
    background: linear-gradient(90deg, #00d7e5 5.8%, #369af3 15.41%, #6764ff 36.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 10px;
    
  }
    @media (max-width: 899px) {
    .title-gradient, h2 {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 30px;
    }
}
`;

// const StyledLink = styled.a`
//   color: white;
//   text-decoration: none;
//   position: relative;
//   display: inline-flex;
//   align-items: center;
//   padding-bottom: 4px; /* Add padding to make space for the border */
//   transition: color 0.3s ease-in-out;

//   &::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 4px;
//     background: #ff6c37; /* Initial orange border */
//     transform: scaleX(1);
//     transform-origin: left;
//     transition: transform 0.3s ease-in-out;
//   }

//   &:hover::before {
//     transform: scaleX(0); /* Hide the orange border on hover */
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 4px;
//     background: linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%);
//     transform: scaleX(0);
//     transform-origin: left;
//     transition: transform 0.3s ease-in-out;
//   }
//   &:hover {
//     color: white;
//     text-decoration: none;
//   }
//   &:hover::after {
//     transform: scaleX(1); /* Show the gradient border on hover */
//   }
//   span {
//     position: relative;
//     right: 0;
//     transition: transform 0.3s ease, opacity 0.3s ease;
//     opacity: 1;
//   }

//   &:hover span {
//     transform: translateX(2px); /* Adjust the value as needed */
//     opacity: 1;
//   }
// `;

const GradientLine = styled.div`
     background: ${(props) => (props.background === 'light' ? '#B9C1F3' : '#5266e3')};
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  // margin: 0px 0px 40px 0;
`;

/* eslint-disable react/prop-types */
function FullWidthSection({ background, image, childPage, title, subtitle, body, stackedItem, extraContent, paddingTop, divider, quoteSection, border, share, paddingTopMobile, paddingBottom }) {
  return (
    <StylesWrapper paddingTop={paddingTop} border={border} background={background} paddingTopMobile={paddingTopMobile} paddingBottom={paddingBottom}>
      <div className="container">
        {title && (
          <div className="row">
            <div className="col-lg-10 mb-4 mb-lg-0">
              {childPage && <p className="eyebrow mb-2">2024 State of API Report</p>}
              {title && childPage && <h2 className="title-gradient ">{title}</h2>}
              {title && !childPage && <h2 className=" ">{title}</h2>}
              {subtitle && <p className="subtitle ">{subtitle}</p>}
              {body && <p className="">{body}</p>}
            </div>
          </div>
        )}
        {stackedItem && (
          <div className="row">
            <div className='col-12'>
              <div key={pmUuid()} className='mb-4 mb-lg-0'>
                {stackedItem.image && <img src={stackedItem.image.src} alt={stackedItem.image.alt} fetchpriority="high" className="img-fluid mb-4" />}
                <div className="row">
                  <div className="col-lg-8">
                    {stackedItem.title && <p className="h3 ">{stackedItem.title}</p>}
                    {stackedItem.body && <p className="mb-0">{stackedItem.body}</p>}
                    {stackedItem.link && (
                      <a id={stackedItem.link.id} href={stackedItem.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                        {stackedItem.link.text}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {image &&
          <img src={image.src} alt={image.alt} className="img-fluid mb-4" />
        }
        {divider && (
          <GradientLine style={{ marginTop: '60px' }} background={background} />
        )}
        {extraContent && (
          <>
            {extraContent.icon && (
              <div className="row align-items-center mb-4">
                <div className="col-auto d-flex align-items-center ">
                  <img src={withPrefix('https://placehold.co/42X42')} alt={extraContent.icon.alt} className="img-fluid" />
                  <p className="h3 ml-2 mb-0">{extraContent.icon.title}</p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-8 ">
                {extraContent.title && <p className="h3 ">{extraContent.title}</p>}
                {extraContent.body && <p className="">{extraContent.body}</p>}
                {extraContent.link && (
                  <a id={extraContent.link.id} href={extraContent.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                    {extraContent.link.text}
                  </a>
                )}
              </div>
            </div>
          </>
        )}
        {quoteSection && (
          <div className="row" style={{marginTop: '30px'}}>
            <div className="col-lg-1">
              <img src="https://voyager.postman.com/icon/state-of-api/icon-quote.svg" alt="Quote" className="img-fluid" />
            </div>
            <div className="col-lg-11">
              {quoteSection.quote && <p className="subtitle quote">{quoteSection.quote}</p>}
              {quoteSection.author && (
                <p className="mb-0">
                  <strong>{quoteSection.author}</strong>, {quoteSection.company}
                </p>
              )}
            </div>
          </div>
        )}
        {share && <SocialShare content={share.content} divider={false} gradient={true}/>}
      </div>
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default FullWidthSection;
