import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement } from 'chart.js';
import PropTypes from 'prop-types';
// import ChartKey24 from './ChartKey24';

// Register necessary components for Chart.js
ChartJS.register(Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement);

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getPercentageLabel = (tooltipItem, data) => {
  const { dataIndex } = tooltipItem;
  const dataset = data.datasets[tooltipItem.datasetIndex];
  const value = dataset?.data?.[dataIndex];

  return `${value}%`;
};

const wrap = (str, limit) => {
  const words = str.split(" ");
  let aux = [];
  let concat = [];

  for (let i = 0; i < words.length; i++) {
    concat.push(words[i]);
    let join = concat.join(' ');
    if (join.length > limit) {
      aux.push(join);
      concat = [];
    }
  }

  if (concat.length) {
    aux.push(concat.join(' ').trim());
  }

  return aux;
};

function BarChart({ data, options, isStacked }) {
  const showPercentage = options?.plugins?.tooltip?.callbacks?.showPercentage;
  const appendPercentage = options?.plugins?.tooltip?.callbacks?.appendPercentage;
  const percentageOnTicks = (value) => `${value}%`;
  const isPercentageOnXTicksReferenced = options?.scales?.x?.ticks?.callback === "percentageOnTicks";
  const isPercentageOnYTicksReferenced = options?.scales?.y?.ticks?.callback === "percentageOnTicks";

  const customOptions = {
    ...options,
    plugins: {
      ...options?.plugins,
      legend: {
        display: false // Ensure legend is displayed for multiple datasets
      },
      tooltip: {
        ...options?.plugins?.tooltip,
        callbacks: {
          label: (tooltipItem) => {
            const label = data.labels[tooltipItem.dataIndex];
            const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex];
            const formattedValue = formatNumberWithCommas(value);
            let tooltipLabel = `${label}: ${formattedValue}`;
            if (appendPercentage) {
              tooltipLabel = `${label}: ${formattedValue}%`;
            }
            if (showPercentage) {
              const percentageLabel = getPercentageLabel(tooltipItem, data);
              return `${label} ${percentageLabel}`;
            }

            return tooltipLabel;
          }
        }
      }
    },
    scales: {
      x: {
        display: true,
        beginAtZero: true,
        stacked: isStacked,
        ticks: {
          color: '#44485A',
          lineColor: '#7C839C',
          size(context) {
            return Math.round(context.chart.width / 32);
          },
          ...(isPercentageOnXTicksReferenced && { callback: percentageOnTicks }),
        },
        grid: {
          drawOnChartArea: false,
          display: false,
          color: '#7C839C',
          offset: false,
          tickWidth: 4
        },
        border: {
          color: '#7C839C'
        }
      },
      y: {
        display: true,
        beginAtZero: true,
        stacked: isStacked,
        ticks: {
          display: true,
          lineColor: '#7C839C',
          color: '#44485A',
          size(context) {
            return Math.round(context.chart.width / 32);
          },
          callback: function (value, index, values) {
            // Convert the value to a string and wrap it
            const label = this.getLabelForValue(value);
            return wrap(label, 15);
          },
          ...(isPercentageOnYTicksReferenced && { callback: percentageOnTicks }),
        },
        grid: {
          drawOnChartArea: false,
          display: false,
          color: '#7C839C',
          borderColor: 'red',
          offset: false,
          tickWidth: 24
        },
        border: {
          color: '#7C839C'
        }
      }
    }
  };

  // Ensure datasets is defined and is an array
  const safeData = {
    ...data,
    datasets: Array.isArray(data?.datasets)
      ? data.datasets.map((dataset) => ({
        ...dataset,
        hoverBackgroundColor: dataset.backgroundColor // Set hoverBackgroundColor to be the same as backgroundColor
      }))
      : [],
    labels: Array.isArray(data?.labels) ? data.labels : []
  };
  return (
    <div>
      <Bar data={safeData} options={customOptions} />
      {safeData.chartKey && (
        <div className="chart-key">
          {safeData.datasets.map((dataset, index) => (
            <div key={index} className="d-flex align-items-center justify-content-center mt-2">
              {Array.isArray(dataset.backgroundColor) ? (
                dataset.backgroundColor.map((color, colorIndex) => (
                  <span key={colorIndex} className="chart-key-color me-2" style={{ backgroundColor: color, width: '20px', height: '20px' }}></span>
                ))
              ) : (
                <span className="chart-key-color me-2" style={{ backgroundColor: dataset.backgroundColor, width: '20px', height: '20px' }}></span>
              )}
              <span className="chart-key-label ml-2">{dataset.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

BarChart.propTypes = {
  isStacked: PropTypes.bool,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])),
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.arrayOf(PropTypes.number),
        backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        borderWidth: PropTypes.number,
        hoverBorderColor: PropTypes.string,
        borderColor: PropTypes.string
      })
    )
  }),
  options: PropTypes.shape({
    plugins: PropTypes.shape({
      legend: PropTypes.shape({
        display: PropTypes.bool
      }),
      tooltip: PropTypes.shape({
        callbacks: PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        })
      }),
      showPercentage: PropTypes.bool,
      showNumber: PropTypes.bool
    })
  }),
  subtitle: PropTypes.string
};

BarChart.defaultProps = {
  data: {
    labels: [],
    datasets: []
  },
  options: {},
  isStacked: false,
  subtitle: ''
};

export default BarChart;