import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const ColorSpan = styled.span`
  display: inline-block;
  min-height: 30px;
  max-height: 30px;

  min-width: 30px;
  max-width: 30px;
`;

const TextSpan = styled.span`
  display: inline-flex;
  align-items: center;
  color: #44485A;
  .mobile-bold {
    @media screen and (max-width: 768px) {
      font-weight: bold;
      width: 50%;
    }
  }
`;

function ChartKey({
  labels,
  dataset,
  backgroundColor = [],
  displayLabelOnlyAndOmitValues, 
  isPercent = true,
  insertBrAfterItemName = false,
  useDatasetLabels,
  datasets
}) {
  const labelsToUse = useDatasetLabels ? datasets.map((ds) => ds.label) : labels;

  const chartKey = labelsToUse.map((label, i) => {
    let itemString;
    let value;

    const sanitizedLabel = label.toString().replace(/,/g, ' ');

    if (dataset[i] >= 1000000) {
      value = `${(dataset[i] / 1000000).toFixed(1)} million`;
    } else if (!isPercent) {
      // Add commas to large numbers
      value = dataset[i].toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(',');
    } else {
      value = dataset[i];
    }

    if (insertBrAfterItemName) {
      itemString = `<div><span class="mobile-bold ">${sanitizedLabel}:</span><br />${value}${isPercent ? '%' : ''}</div>`;
    } else if (displayLabelOnlyAndOmitValues) {
      itemString = `<span class="mobile-bold">${sanitizedLabel}</span>`;
    } else {
      itemString = `<span class="mobile-bold">${sanitizedLabel}</span>: ${value}${isPercent ? '%' : ''}`;
    }

    return (
      <div key={pmUuid()} className="d-flex align-items-center mb-2">
        {backgroundColor[i] && <ColorSpan className="mr-2" style={{ backgroundColor: backgroundColor[i] }} />}
        {sanitizedLabel && <TextSpan alternative dangerouslySetInnerHTML={{ __html: itemString }} />}
      </div>
    );
  });

  return <div>{chartKey}</div>;
}

export default ChartKey;

ChartKey.defaultProps = {
  backgroundColor: [],
  isPercent: true,
  insertBrAfterItemName: false,
  displayLabelOnlyAndOmitValues: false,
  labels: null,
  dataset: null,
  className: '',
  useDatasetLabels: false,
  keyside: false,
  datasets: null
};

ChartKey.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.objectOf]),
  isPercent: PropTypes.bool,
  insertBrAfterItemName: PropTypes.bool,
  displayLabelOnlyAndOmitValues: PropTypes.bool,
  labels: PropTypes.instanceOf(Array),
  dataset: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  useDatasetLabels: PropTypes.bool,
  keyside: PropTypes.bool,
  datasets: PropTypes.instanceOf(Array)
};
