import React, { useState } from 'react';
import styled from 'styled-components';
import bffFooterData from '../../../../bff-data/footer.json';

/* eslint-disable import/no-extraneous-dependencies */
const pmRr = require('@postman/rr');
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link

const footerData = pmRr(bffFooterData);

const Footer = styled.footer`
  background-color: #0d1629;
  color: #fff;
  padding-top: ${(props) => props.paddingTop || '30px'};
  padding-bottom: 30px;
  border-top: 10px solid #ff6c37;
`;
const FooterLinkStyles = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #5266e3;
`;
const Column = styled.ul`
  padding-left: 0;
`;

const ColumnRow = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  padding: 5px 0;
`;

const FooterImg = styled.span`
  @media (min-width: 768px) {
    img {
      &:hover {
      //  filter: drop-shadow(16px 16px 20px  #5266e3) brightness(100%);
        // transition: ${(props) => props.theme.transitions.all};
      }
    }
  }
`;

const Copyright = styled.span`
  color: #fff !important;
  font-size: 1.2rem;
`;

const SocialSVG = styled.div`
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  height: 16px;

  &.github-svg path {
    fill: white !important;
  }
  & svg {
    max-height: 100%;
    width: auto;
  }
  &:hover {
    border-bottom: unset;
  }
`;

const FooterColTitle = styled.h2`
  font-size: 1.6rem !important;
  line-height: 1.4;
  margin-bottom: 8px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  color: #fff;
  font-feature-settings: 'calt' 0;
`;

const triggerGA = (category, label) => {
  return category && label && window.pmt && window.pmt('ga', ['send', 'event', category, 'Click', label]);
};

// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-external-company') {
    return 'noopener noreferrer';
  }
  if (target === 'new-tab-external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (target === 'new-tab-postman') {
    return 'noopener';
  }
  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  if (target === 'new-tab-external-company' || target === 'new-tab-external-nofollow' || target === 'new-tab-postman') {
    return '_blank';
  }
  return null;
}

function FooterSota() {
  const [data] = useState(footerData);
  const columns = data.items.slice(0, 5);

  return (
    <Footer>
      <div className="container small">
        <div className="row">
          <div className="col-sm-8 offset-sm-2 col-md-12 offset-md-0">
            <div className="row">
              {/* First column */}
              <div className=" col-8 offset-2 col-md-3 offset-md-0 col-lg-2 order-12 order-md-0 pad-md-right align-self-center">
                <FooterImg>
                  <img className="img-fluid col-12 col-sm-9 col-md-12 mb-5 d-block mx-auto" src="https://voyager.postman.com/illustration/state-of-api/postman-footer-rocket-launch-dark-sota.svg" alt={data.alt} fetchpriority="low" loading="lazy" />
                </FooterImg>
                <Copyright className="col-12 d-block text-center">{data.copyright}</Copyright>
              </div>
              {/* Second column */}
              <div className="col-6 col-md-2 order-2 order-md-3 offset-lg-2 mb-5 mb-md-0">
                {columns.slice(0, 1).map((item) => (
                  <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                    <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                    <Column>
                      {(item.items &&
                        item.items.map((link) => (
                          <ColumnRow key={pmUuid()}>
                            <FooterLinkStyles
                              href={link.url}
                              rel={relStringGenerator(link.target)}
                              target={targetStringGenerator(link.target)}
                              aria-label={link.ariaLabel}
                              onClick={() => {
                                triggerGA(link.category, link.label);
                              }}
                            >
                              {link.title}
                            </FooterLinkStyles>
                          </ColumnRow>
                        ))) ||
                        ''}
                    </Column>
                  </nav>
                ))}
              </div>
              <div className="col-6 col-md-2 order-2 order-md-3">
                {/* Third column - stacked - top */}
                {columns.slice(1, 2).map((item) => (
                  <div key={pmUuid()}>
                    <nav aria-labelledby={item.arialabelledby} style={{ marginBottom: `${37}px` }}>
                      <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                      <Column>
                        {(item.items &&
                          item.items.map((link) => (
                            <ColumnRow key={pmUuid()}>
                              <FooterLinkStyles
                                href={link.url}
                                rel={relStringGenerator(link.target)}
                                target={targetStringGenerator(link.target)}
                                aria-label={link.ariaLabel}
                                onClick={() => {
                                  triggerGA(link.category, link.label);
                                }}
                              >
                                {link.title}
                              </FooterLinkStyles>
                            </ColumnRow>
                          ))) ||
                          ''}
                      </Column>
                    </nav>
                  </div>
                ))}
                {/* Third column - stacked - bottom */}
                {columns.slice(2, 3).map((item) => (
                  <div key={pmUuid()}>
                    <nav aria-labelledby={item.arialabelledby} className="mb-5">
                      <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                      <Column>
                        {(item.items &&
                          item.items.map((link) => (
                            <ColumnRow key={pmUuid()}>
                              <FooterLinkStyles
                                href={link.url}
                                rel={relStringGenerator(link.target)}
                                target={targetStringGenerator(link.target)}
                                aria-label={link.ariaLabel}
                                onClick={() => {
                                  triggerGA(link.category, link.label);
                                }}
                              >
                                {link.title}
                              </FooterLinkStyles>
                            </ColumnRow>
                          ))) ||
                          ''}
                      </Column>
                    </nav>
                  </div>
                ))}
              </div>
              {/* Fourth Column API Categories */}
              <div className="col-6 col-md-2 order-3 order-md-4">
                {columns.slice(3, 4).map((item) => (
                  <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                    <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                    <Column>
                      {(item.items &&
                        item.items.map((link) => (
                          <ColumnRow key={pmUuid()}>
                            <FooterLinkStyles
                              href={link.url}
                              rel={relStringGenerator(link.target)}
                              target={targetStringGenerator(link.target)}
                              aria-label={link.ariaLabel}
                              onClick={() => {
                                triggerGA(link.category, link.label);
                              }}
                            >
                              {link.title}
                            </FooterLinkStyles>
                          </ColumnRow>
                        ))) ||
                        ''}
                    </Column>
                  </nav>
                ))}
              </div>
              {/* Fifth column social */}
              <div className="col-6 col-md-2 order-4 order-md-5">
                <div className="row social">
                  {columns.slice(4, 5).map((item) => (
                    <div className="col-sm-12" key={pmUuid()}>
                      <nav aria-labelledby={item.arialabelledby} className="mb-5">
                        <FooterColTitle id={item.arialabelledby}>{item.title}</FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <div className="d-inline-block">
                                  <FooterLinkStyles
                                    href={link.url}
                                    rel={relStringGenerator(link.target)}
                                    target={targetStringGenerator(link.target)}
                                    aria-label={link.ariaLabel}
                                    onClick={() => {
                                      triggerGA(link.category, link.label);
                                    }}
                                  >
                                    <div>
                                      <SocialSVG
                                        className={`d-inline-block align-self-center ${link.title === 'GitHub' ? 'github-svg' : ''}`}
                                        dangerouslySetInnerHTML={{
                                          __html: link.svg
                                        }}
                                      />
                                      {link.title}
                                    </div>
                                  </FooterLinkStyles>
                                </div>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  eslint-enable */}
      </div>
    </Footer>
  );
}

export default FooterSota;
