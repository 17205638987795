import React, { useState, useEffect, useRef } from 'react';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';

const Navbar = styled.nav`
  background-color: #0d1629;
  position: sticky;
  top: 54px;
  width: 100%;
  z-index: 22;
  border-bottom: 2px solid #5266E3;
  padding: 16px 0;
  color: #e2e8ff !important;

 .title-link  {
    font-size: 20px;
    font-weight: 600;
     color: #e2e8ff;
     line-height: 1.5;
    }  

.dropdown-btn {
     color: #e2e8ff !important;
    background-color: transparent !important;
    border: none !important;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
}
 .key-link  {
    font-size: 14px;
    color: #e2e8ff;
    text-decoration: none;
    font-weight: 600;
    
    }

.key-link:hover {
color: #fff;
}
 .dropdown-btn:hover {
    text-decoration: none;
    color: #fff !important;
    font-weight: 600;
    cursor: pointer;
    border-bottom: none;
}
    .link:focus-visible, .dropdown-btn {
    outline: none !important;
    }
    .rotate {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
    }
    // .active {
    // color: #fff !important;
    // font-weight: 600;
    // }
  `;
const DropdownMenu = styled.div`
    display: inline-grid;
    padding: 30px;
    background-color: #0d1629;
    border: 2px #5266e3 solid;
    border-radius: 15px 20px;
    width: 100%;
    top: 100%;
    position: absolute;
    height: 420px;
    max-height: 425px;
    visibility: visible;
    transition: all 0.4s ease-in-out;
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
    animation-duration: 0.4s;
    animation-fill-mode: both;
    box-shadow: 0 16px 24px -8px rgb(0 0 0 / 24%);
@keyframes slideIn {
    0% {
        transform: translateY(0rem);
        opacity: 0;
    }
    100% {
        transform: translateY(1rem);
        opacity: 1;
    }
}

.dropdown-link  {
    font-size: 14px;
    color: #e2e8ff !important;
    text-decoration: none;
    font-weight: 600;
    }
    .dropdown-link {
    padding: 0px 0px 8px 0px;
}
  
.dropdown-link:not(:last-child) {
    margin-bottom: 8px;
}
    .dropdown-link:hover {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    background: #172144;
    padding: 8px;
    margin-left: -8px;
    margin-top: -8px;
    border-radius: 5px;
    
}
    .dropdown-link a:focus-visible  {
    outline: none !important;
    }

@media (min-width: 992px) {
        width: 224px;
        right: -80px;

}
`;
const StyledButton = styled.a`
 align-items: center;
   border-radius: 5px;
  text-align: center;
  border: 2px solid #E2E8FF;
  color: #E2E8FF;
  background: 
    linear-gradient(#0d1629, #0d1629) padding-box,
    linear-gradient(90.93deg, #00D7E5 0.8%, #369AF3 51.41%, #6764FF 96.62%) border-box;
  transition: all .5s ease;
  
  padding: 2px 12px !important;
  display: table;
  letter-spacing: 0.125rem;

.wrapper {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.75;
  letter-spacing: -0.16px;
  appearance: none;
  display: table-cell;
  vertical-align: middle;
}

    &:hover {
    border: 2px solid #0000;
    text-decoration: none;
    .wrapper{
    background: linear-gradient(90.93deg, #00D7E5 0.8%, #369AF3 51.41%, #6764FF 96.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    }
    }
    &:focus {
        outline: 0 !important;
    }
`;

const Navbar24 = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isDropdownActive, setDropdownActive] = useState(false);
    const dropdownRef = useRef(null);
    const paths = [
        { path: '/2024/api-global-growth/', label: 'Global growth of APIs' },
        { path: '/2024/who-works-with-apis/', label: 'Who works with APIs' },
        { path: '/2024/api-use-and-discovery/', label: 'API use & discovery' },
        { path: '/2024/api-production/', label: 'API production' },
        { path: '/2024/api-priorities-and-outlook/', label: 'API priorities & outlook' },
        { path: '/2024/api-collaboration/', label: 'API collaboration' },
        { path: '/2024/api-monetization/', label: 'API monetization' },
        { path: '/2024/artificial-intelligence/', label: 'Artificial intelligence' },
        { path: '/2024/api-technologies/', label: 'API technologies' }
    ];

    useEffect(() => {
        const checkDropdownActive = () => {
            if (typeof window !== 'undefined') {
                const currentPath = window.location.pathname;
                const result = paths.some(({ path }) => {
                    const prefixedPath = withPrefix(path);
                    return currentPath === prefixedPath;
                });
                setDropdownActive(result);
            }
        };
        checkDropdownActive();
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [paths]);

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const keyClass = typeof window !== 'undefined' && window.location.pathname === '/state-of-api/2024/' ? 'active' : '';
    const idpath = typeof window !== 'undefined' && window.location.pathname.replace('/state-of-api/2024/', '');
    return (
        <Navbar>
            <div className='d-flex flex-lg-row flex-column align-items-center pl-lg-3'>
                <h4 className='title-link mb-2 mb-lg-0 mr-lg-4 mr-0'>2024 State of API Report</h4>
                <div className="d-flex flex-column align-items-center flex-lg-row position-relative text-center text-lg-left" ref={dropdownRef}>
                    <a href={withPrefix('/2024/')} id="state-of-api-2024-navbar-key-trends" className={`key-link mb-0 mr-4 mb-2 mb-lg-0 ${keyClass}`}>Key trends in API development</a>
                    <button className={`dropdown-btn ${isDropdownActive ? 'active' : ''}`} onClick={toggleDropdown}>
                        Report pages
                        <svg
                            className={`ml-1 ${isDropdownVisible ? 'rotate' : ''}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="#e2e8ff"
                        >
                            <g>
                                <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
                            </g>
                        </svg>
                    </button>
                    {isDropdownVisible && (
                        <DropdownMenu >
                            {paths.map(({ path, label }) => (
                                <a key={path} href={withPrefix(path)} id={`state-of-api-2024-nav-${idpath}`} className={`dropdown-link `}>{label}</a>
                            ))}

                        </DropdownMenu>
                    )}
                </div>
                <div className='ml-lg-auto mt-3 mt-lg-0 mr-lg-3'>
                    <StyledButton
                        href="https://voyager.postman.com/doc/postman-state-of-the-api-report-2024.pdf"
                        target="new-tab"
                        id="download-button"
                        gaCategory="download-button"
                        gaLabel="download-button"
                    >
                        <span className="wrapper">
                            Download the report
                        </span>
                    </StyledButton>
                </div>
            </div>
        </Navbar>
    );
};

export default Navbar24;