import React from 'react';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import { UnorderedListStyles } from 'aether-marketing';
import Chart24 from './Chart24';
import ChartKey24 from './ChartKey24';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link
const StylesWrapper = styled.section`
  background-color: #e2e8ff;
  padding-top: ${(props) => props.paddingTop || '30px'};
  padding-bottom:${(props) => props.paddingBottom || '30px'};
  color: #172144;

   h2 {
    font-size: 55px !important;
    line-height: 1.1;
    font-weight: 700;
    line-height: 45px;
  }
    h3 {
    font-size: 30px;
    }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
 a {
  color: #0265D2;
}
a:hover {
  color: #0265D2;
  border-bottom: 1px solid #0265D2;
  text-decoration: none;
}
  .title-gradient {
    font-size: 65px;
    margin-bottom: 10px;
    line-height: 1.1;
    background: linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media (max-width: 899px) {
      font-size: 35px;
      line-height: 35px;
    }
  }
  .tip {
    margin-top: 60px;
  }
  @media (max-width: 899px) {
    .subject {
      font-size: 30px;
    }
    .subtitle {
      font-size: 25px;
    }
  }
  .hover-arrow {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease;
  }
  .hover-arrow::before {
    content: ' →';
    position: absolute;
    right: -20px;
    transition: all 0.3s ease;
  }
  .hover-arrow:hover::before {
    right: -40px;
    opacity: 1;
  }
`;
const GradientLine = styled.div`
  background: #B9C1F3;
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  // margin: 0px 0px 40px 0;
`;

/* eslint-disable react/prop-types */
function SideBySide24Chart({ title, body, leftContent, rightContent, divider, paddingTop, paddingBottom }) {
  return (
    <StylesWrapper paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <div className="container">
        {title && (
          <div className="row">
            <div className="col-lg-8">
              {title && <h2 className="mb-4">{title}</h2>}
              {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
            </div>
          </div>
        )}
        {leftContent.chart && (
          <div className="row">
            <div className="col-lg-8">
              {leftContent.title && <h2 className=" mb-2">{leftContent.title}</h2>}
              {leftContent.body && <div dangerouslySetInnerHTML={{ __html: leftContent.body.join('') }} />}
            </div>
          </div>
        )}
        <div className="row ">
          <div className="col-lg-4 offset-lg-0 align-items-center">
            {leftContent && (
              <>
                {!leftContent.chart && leftContent.title && <h2 className="mb-2">{leftContent.title}</h2>}
                {!leftContent.chart && leftContent.body && <div dangerouslySetInnerHTML={{ __html: leftContent.body.join('') }} />}
                {leftContent.chart && (
                  <div>
                    {leftContent.chart && leftContent.chart.keyside !== true && <Chart24 chart={leftContent.chart} isFullWidth={leftContent.chart.isFullWidth} />}
                    {leftContent.chart && leftContent.chart.keyside && (
                      <div className="row">
                        <div className="col-md-8">
                          <Chart24 chart={leftContent.chart} isFullWidth={leftContent.chart.isFullWidth} />
                        </div>
                        <div className="col-md-4">{leftContent.chart.chartData.chartType !== 'bar' && leftContent.chart.chartData.datasets.length > 0 && leftContent.chart.chartData.labels.length > 0 && <ChartKey24 keyside={leftContent.chart.keyside} labels={leftContent.chart.chartData.labels} dataset={leftContent.chart.chartData.datasets[0].data} backgroundColor={leftContent.chart.chartData.datasets[0].backgroundColor} />}</div>
                      </div>
                    )}
                  </div>
                )}
                {leftContent.tip &&
                  leftContent.tip.map((item) => (
                    <div key={pmUuid()} className="tip">
                      <div className="row align-items-center mb-4">
                        <div className="col-auto d-flex align-items-center ">
                          <img src={withPrefix(item.img.src)} alt={item.img.alt} className="img-fluid" />
                          <p className="h3 ml-2 mb-0">{item.title}</p>
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: item.body.join('') }} />
                    </div>
                  ))}
              </>
            )}
          </div>

          <div className="col-lg-7 offset-lg-1 align-items-center">
            {rightContent.chart && rightContent.chart.keyside !== true && <Chart24 chart={rightContent.chart} isFullWidth={rightContent.chart.isFullWidth} />}
            {rightContent.chart && rightContent.chart.keyside && (
              <div className="row">
                <div className="">
                  <Chart24 chart={rightContent.chart} isFullWidth={rightContent.chart.isFullWidth} />
                </div>
                <div className="">{rightContent.chart.chartData.chartType !== 'bar' && rightContent.chart.chartData.datasets.length > 0 && rightContent.chart.chartData.labels.length > 0 && <ChartKey24 keyside={rightContent.chart.keyside} labels={rightContent.chart.chartData.labels} dataset={rightContent.chart.chartData.datasets[0].data} backgroundColor={rightContent.chart.chartData.datasets[0].backgroundColor} />}</div>
              </div>
            )}
          </div>
        </div>
        {divider && (
          <GradientLine style={{ marginTop: '60px' }} />
        )}
      </div>
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default SideBySide24Chart;
