import React from 'react';
import styled from 'styled-components';

const HeroWrapper = styled.section`
  background-color: #0d1629;
  padding-top: 60px;
  padding-bottom: 60px;
h1 {
    font-size: 100px;
  }

  @media (max-width: 992px) {
    padding-top: 30px;
    text-align: center;
    h1 {
      font-size: 40px;
    }
  }
`;

// // padding-top: 70px;
//   max-width: 1200px;
//   position: absolute;
//   top: 170px;
//   right: -250px;
//   // right: clamp(-140px, -5vw, -100px);
//   width: clamp(80%, 10vw, 1200px);
//   @media (max-width: 992px) {
//     display: none;
//     }

//    @media (min-width: 1802px) {
//     top: 0;
//     right: 200px;
//   }
// `;
function Hero24() {
  return (
    <HeroWrapper>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <h1 className="text-white">2024 State of the API Report</h1>
          </div>
          <div className='col-lg-7'>
          <img className="img-fluid" fetchpriority="high" alt="API letters on blocks in multiple colors. Illustration." src='https://voyager.postman.com/illustration/state-of-api/postman-state-of-api-2024-illustration.svg' />
          </div>
        </div>
      </div>
    </HeroWrapper>
  );
}


export default Hero24;
