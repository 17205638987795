/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import { HeroStacked, BackLinkSection, CallOut, SideXSide, Feature, VideoComponent, Divider, Hero } from 'aether-marketing';
import apiSurvey2019 from './Shared/state-of-api-2019/apiSurvey2019';
import apiSurvey2018 from './Shared/state-of-api-2018/apiSurvey2018';
import stickyModalNav from './Shared/navs-mobile/sticky-modal-nav/stickyModalNav';
import stickyModalNavStateOfAPI2021 from './Shared/state-of-api-2021/stickyModalNavStateOfAPI2021';
import hero from './Shared/heroes/Hero';
import footer from './Shared/footer';
import heroStateOfAPI2021 from './Shared/state-of-api-2021/heroStateOfAPI2021';
import heroStateOfAPI2022 from './Shared/state-of-api-2022/heroStateOfAPI2022';
import heroStateOfAPI2023 from './Shared/state-of-api-2023/heroStateOfAPI2023';
import stickySideBar from './Shared/sidebars/stickySideBar';
import headerRow from './Shared/texts/headerRow';
import headerRowStickyShrink from './Shared/texts/headerRowStickyShrink';
import headerRowStickyShrinkStateOfAPI2021 from './Shared/state-of-api-2021/headerRowStickyShrinkStateOfAPI2021';
import headerRowStickyShrinkStateOfAPI2022 from './Shared/state-of-api-2022/headerRowStickyShrinkStateOfAPI2022';
import leftTitleWithText from './Shared/leftTitleWithText';
import chart2023 from './Shared/state-of-api-2023/chart2023';
import globalGrowth2022Section from './Shared/state-of-api-2023/globalGrowth2022Section';
import prevNextPageButtonSection from './Shared/prevNextPageButtonSection';
import PrevNextButtonSectionStateOfAPI2021 from './Shared/state-of-api-2021/PrevNextButtonSectionStateOfAPI2021';
import PrevNextButtonSectionStateOfAPI2022 from './Shared/state-of-api-2022/PrevNextButtonSectionStateOfAPI2022';
import sideBarStateOfApi2021 from './Shared/state-of-api-2021/sideBarStateOfApi2021';
import mobileToCButtonStateOfAPI2022 from './Shared/state-of-api-2022/MobileToCButtonStateOfAPI2022';
import mobileToCButtonContainerStateOfAPI2023 from './Shared/state-of-api-2023/MobileToCButtonContainerStateOfAPI2023';
import sideBar from './Shared/sidebars/sideBar';
import keyFindingsTextCollapse from './Shared/state-of-api-2021/keyFindingsTextCollapse';
import keyFindingsTextCollapseStateOfAPI2022 from './Shared/state-of-api-2022/keyFindingsTextCollapseStateOfAPI2022';
import OrangeBorderContainer from './Shared/state-of-api-2023/OrangeBorderContainer';
import sideBarStateOfApi2023 from './Shared/state-of-api-2023/sideBarStateOfApi2023';
import KeyFindings from './Shared/state-of-api-2023/KeyFindings';
import horizontalThumbnailSection from './Shared/cards/horizontalThumbnailSection';
import quoteStateOfAPI2021 from './Shared/state-of-api-2021/quoteStateOfAPI2021';
import TextSection from './Shared/state-of-api-2023/TextSection';
import SideBySideFooter from './Shared/SideBySideFooter';
import Hero24 from './Shared/state-of-api-2024/Hero24';
import NavBar24 from './Shared/state-of-api-2024/NavBar24';
import CallOut24 from './Shared/state-of-api-2024/Callout24';
import SideBySide24 from './Shared/state-of-api-2024/SideBySide24';
import SocialShare from './Shared/state-of-api-2024/SocialShare';
import FooterSota from './Shared/state-of-api-2024/FooterSota';
import Chart24 from './Shared/state-of-api-2024/Chart24';
import SideBySide24Chart from './Shared/state-of-api-2024/SideBySide24Chart';
import FullWidthSection from './Shared/state-of-api-2024/FullWidthSection';
import TextSection24 from './Shared/state-of-api-2024/TextSection24';
import PrevNextSection from './Shared/state-of-api-2024/PrevNextSection';

export default {
  hero,
  footer,
  heroStateOfAPI2021,
  heroStateOfAPI2022,
  heroStateOfAPI2023,
  apiSurvey2018,
  apiSurvey2019,
  stickyModalNav,
  stickyModalNavStateOfAPI2021,
  mobileToCButtonStateOfAPI2022,
  mobileToCButtonContainerStateOfAPI2023,
  stickySideBar,
  headerRow,
  headerRowStickyShrink,
  headerRowStickyShrinkStateOfAPI2021,
  headerRowStickyShrinkStateOfAPI2022,
  leftTitleWithText,
  chart2023,
  prevNextPageButtonSection,
  PrevNextButtonSectionStateOfAPI2021,
  PrevNextButtonSectionStateOfAPI2022,
  sideBar,
  sideBarStateOfApi2021,
  sideBarStateOfApi2023,
  keyFindingsTextCollapse,
  keyFindingsTextCollapseStateOfAPI2022,
  OrangeBorderContainer,
  KeyFindings,
  HeroStacked,
  BackLinkSection,
  CallOut,
  SideXSide,
  TextSection,
  Feature,
  VideoComponent,
  Divider,
  Hero,
  globalGrowth2022Section,
  horizontalThumbnailSection,
  quoteStateOfAPI2021,
  SideBySideFooter,
  Hero24,
  NavBar24,
  CallOut24,
  SideBySide24,
  SideBySide24Chart,
  SocialShare,
  FooterSota,
  Chart24,
  FullWidthSection,
  TextSection24,
  PrevNextSection
};
