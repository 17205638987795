import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import PropTypes from 'prop-types';
import ChartKey24 from './ChartKey24';

// Register necessary components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title);

function DoughnutChart({ chartTitle, data, options, subtitle, keyside, kitchen }) {
  const customOptions = {
    ...options,
    plugins: {
      ...options?.plugins,
      legend: {
        display: false // Disable the default legend
      },
      tooltip: {
        ...options?.plugins?.tooltip,
        callbacks: {
          label: (tooltipItem) => {
            const { dataIndex } = tooltipItem;
            const { dataset } = tooltipItem;
            const value = dataset?.data?.[dataIndex];
            return `${dataset?.label}: ${value}%`;
          }
        }
      }
    }
  };

    // Ensure datasets and labels are defined and are arrays
    const safeData = {
      ...data,
      datasets: Array.isArray(data?.datasets)
        ? data.datasets.map((dataset) => ({
            ...dataset,
            hoverBackgroundColor: dataset.backgroundColor 
          }))
        : [],
      labels: Array.isArray(data?.labels) ? data.labels : []
    };

  // Extract backgroundColor from the first dataset for ChartKey24
  const backgroundColor = safeData.datasets[0]?.backgroundColor || [];
  return (
    <div className='d-lg-flex'>
      <div
        className="mr-4 mb-4 mt-lg-0"
        style={{
          position: 'relative',
          maxWidth: '452px'
        }}
      >
        {chartTitle && <h2>{chartTitle}</h2>}
        <Doughnut data={safeData} options={customOptions} />
      </div>
       <ChartKey24  keyside={keyside} labels={safeData.labels} dataset={safeData.datasets[0].data} backgroundColor={backgroundColor} />
    </div>
  );
}

DoughnutChart.propTypes = {
  chartTitle: PropTypes.string,
  kitchen: PropTypes.bool,
  keyside: PropTypes.bool,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
        backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
        borderWidth: PropTypes.number,
        hoverBorderColor: PropTypes.string,
        borderColor: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  options: PropTypes.shape({
    plugins: PropTypes.shape({
      legend: PropTypes.shape({
        display: PropTypes.bool
      }),
      tooltip: PropTypes.shape({
        callbacks: PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        })
      })
    })
  }),
  subtitle: PropTypes.string
};
DoughnutChart.defaultProps = {
  chartTitle: '',
  options: {},
  subtitle: '',
  keyside: false,
  kitchen: false
};

export default DoughnutChart;
