import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ButtonCopy from './ButtonCopy';

const SocialShareWrapper = styled.section`
  padding-top: 30px;
  .plain {
    z-index: 10;
    color: black !important;
    font-size: 22px;
    font-weight: 600;
    padding-right: 30px;
    line-height: 1;
}
  .gradient {
    background: linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 22px;
    padding-right: 30px;
    font-weight: 600;
    line-height: 1;
  }
  
`;
const GradientLine = styled.div`
  background: #5266e3;
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  margin: 0px 0px 40px 0;
`;
const StyledButton = styled.button`
  z-index: 20;
  border: none;
  background-color: transparent;
  color: ${(props) => (props.gradient ? '#ffffff' : '#5266e3')};
  cursor: pointer;
  padding-right: 30px;
  padding-left: 0px;
  svg:hover {
    color: #FF6C37;
  }
  :active,
  :focus,
  :focus-visible,
  svg:focus {
    border: none;
    outline: none;
  }

`;
/* eslint-disable react/prop-types */
const shareContent = (platform, content) => {
  const url = encodeURIComponent(window.location.href);
  const text = encodeURIComponent(content);
  let shareUrl = '';

  if (platform === 'twitter') {
    shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
  } else if (platform === 'linkedin') {
    shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(text)}`;
  } else if (platform === 'email') {
    const subject = encodeURIComponent('Check out this amazing content!');
    shareUrl = `mailto:?subject=${subject}&body=${text}%20${url}`;
  }

  window.open(shareUrl, '_blank', 'noopener,noreferrer');
};

function SocialShare({ divider = true, content, gradient }) {
  const [twitterButtonId, setTwitterButtonId] = useState(null);
  const [facebookButtonId, setFacebookButtonId] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pageUrl = window.location.pathname
        .replace(/^\//, '') // Remove the leading slash
        .replace(/\/+/g, '-') // Replace subsequent slashes with dashes
        .replace(/-$/, ''); // Remove trailing dash if it exists

      setTwitterButtonId(`twitter-button-${pageUrl}`);
      setFacebookButtonId(`facebook-button-${pageUrl}`);
    }
  }, []);

  if (twitterButtonId === null || facebookButtonId === null) {
    return null; // Render nothing on the server
  }
  return (
    <SocialShareWrapper >
      <div className="">
        {divider && (
          <GradientLine />
        )}
        <div className="d-flex align-items-center">
          <p className={` ${gradient ? 'gradient' : 'plain'} mb-0 `}>Share</p>

          <StyledButton onClick={() => shareContent('twitter', content)} gradient={gradient} id={twitterButtonId}>
            <svg width="24" height="20" viewBox="0 0 24 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M20.5689 3.16198C21.557 2.53799 22.3151 1.54848 22.671 0.369764C21.7458 0.948936 20.7236 1.36791 19.6328 1.59476C18.7625 0.612415 17.5184 0 16.1417 0C13.5005 0 11.3602 2.26063 11.3602 5.04844C11.3602 5.44412 11.3998 5.82985 11.4819 6.19815C7.50772 5.98727 3.9838 3.97957 1.62312 0.923025C1.21114 1.67119 0.975824 2.53799 0.975824 3.46232C0.975824 5.21303 1.82014 6.75858 3.1038 7.6658C2.31962 7.63972 1.58205 7.41011 0.936061 7.03448V7.09673C0.936061 9.54362 2.58378 11.5846 4.77483 12.0469C4.37247 12.1652 3.95088 12.2245 3.513 12.2245C3.205 12.2245 2.90401 12.1942 2.61393 12.1349C3.22146 14.1398 4.98831 15.6016 7.08206 15.6407C5.44395 16.9956 3.38165 17.8016 1.1414 17.8016C0.755499 17.8016 0.373675 17.78 0 17.7322C2.11706 19.1637 4.63109 20 7.33254 20C16.1321 20 20.9411 12.3068 20.9411 5.63478C20.9411 5.41527 20.9383 5.19723 20.9287 4.98195C21.8635 4.2698 22.6763 3.38002 23.3155 2.36753C22.4575 2.76907 21.5351 3.04057 20.5689 3.16198Z" fill="currentColor" />
            </svg>
          </StyledButton>
          <StyledButton onClick={() => shareContent('linkedin', content)} gradient={gradient}  id={facebookButtonId}>
            <svg width="22" height="20" viewBox="0 0 22 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.860223 6.41307H5.07031V19.9987H0.860223V6.41307ZM2.85791 4.71382H2.82755C1.30341 4.71382 0.31543 3.6752 0.31543 2.36003C0.31543 1.01801 1.33264 0 2.88714 0C4.44036 0 5.39543 1.01546 5.42595 2.35619C5.42595 3.67137 4.44037 4.71382 2.85791 4.71382ZM21.9155 20H17.1416V12.9683C17.1416 11.1282 16.3948 9.87323 14.7528 9.87323C13.4967 9.87323 12.7982 10.7197 12.4731 11.538C12.3512 11.83 12.3702 12.2385 12.3702 12.6483V20H7.64075C7.64075 20 7.70178 7.54515 7.64075 6.41307H12.3702V8.54527C12.6497 7.61305 14.1609 6.28254 16.5727 6.28254C19.5647 6.28254 21.9155 8.23789 21.9155 12.4446V20Z" fill="currentColor" />
            </svg>

          </StyledButton>
          <ButtonCopy gradient={gradient} />
        </div>
      </div>
    </SocialShareWrapper>
  );
}
export default SocialShare;
/* eslint-enable react/prop-types */