import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import SocialShare from './SocialShare';

const SectionStyles = styled.section`
  padding-top: 30px;
  padding-bottom: 30px;
  background: ${(props) => props.backgroundColor};
  position: relative;
  overflow: hidden;
  color: #172144;

  h2 {
    font-size: 55px;
    @media (max-width: 899px) {
      font-size: 45px;
      line-height: 1;
    }
  }

  @media (min-width: 769px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .gradientText {
    background-image: linear-gradient(to right, #ff6c37, #c64dda);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }

  .callout-image-left,
  .callout-image-right {
    display: none;
  }
  .text {
    position: relative;
    z-index: 2;
  }

  @media (min-width: 1150px) {
    .callout-image-left,
    .callout-image-right {
      display: block;
      position: absolute;
      bottom: 0;
      height: 100%;
      z-index: 1;
      background: url('https://voyager.postman.com/icon/state-of-api/postman-state-of-api-bg-light.svg'), #e2e8ff;
      background-repeat: repeat;
      background-size: 50px;
      }
      
  .callout-image-left {
    width: 350px;
    box-sizing: border-box;
    --shadow-size: calc(10vw - -10px);
    box-shadow: inset calc(-1 * var(--shadow-size)) 0 var(--shadow-size) 0 #e2e8ff;
    }

  .callout-image-right {
    width: ${(props) => (props.rightImageSet ? props.rightImageSet : '200px')};
    right: -10.5%;
    box-sizing: border-box;
    --shadow-size: calc(10vw - -10px);
    box-shadow: inset var(--shadow-size) 0 var(--shadow-size) 0 #e2e8ff;
    }
  }
    @media (max-width: 1500px) and (min-width: 1150px) {
    .callout-image-left {
    width: calc(10vw - -60px);
      --shadow-size: calc(10vw - -60px);
    }
      .callout-image-right {
      width: calc(10vw - -470px);
      --shadow-size: calc(10vw - -70px);
    }
  }
`;


function CallOut24({ title, body, backgroundColor, rowClassname, bodyClassname, rightImageSet, share }) {
  return (
    <SectionStyles backgroundColor={backgroundColor} rightImageSet={rightImageSet}>
      <div className="container">
        <div className={`text ${rowClassname}`}>
          {title && <div className="col-lg-5 ">{title && <h2 className="">{title}</h2>}</div>}
          {body && (<div className={` ${bodyClassname}`}>
            <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
          </div>
          )}
        </div>
      {share && <SocialShare divider={false} gradient={false} content={share.content}/>}
      </div>
      <span className="callout-image-left" />
      <span className="callout-image-right"/>
    </SectionStyles>

  );
}
CallOut24.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  backgroundColor: PropTypes.string,
};

CallOut24.defaultProps = {
  title: null,
  body: null,
  backgroundColor: null
};

export default CallOut24;
