import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
  background-color: #E2E8FF;
  width: 100%;
  a:hover {
    text-decoration: none !important;
  }
`;

const StyledButton = styled.a`
  font-family: Degular, sans-serif;
  border: 2px solid transparent;
  border-radius: 20px;
  background-image: linear-gradient(#E2E8FF, #E2E8FF), 
                    linear-gradient(90deg, #00D7E5, #369AF3, #6764FF);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;

  .wrapper {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
  }

  .text-gradient {
    color: #5075F6;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  .h3 {
    color: #212121;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    &:hover {
      transition: background 0.3s ease;
      background: linear-gradient(90deg, #00D7E5, #369AF3, #6764FF);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }
`;

const GradientLine = styled.div`
  background: #B9C1F3;
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  margin: 0px 0px 40px 0;
`;

const paths = [
  { path: '/state-of-api/2024/', label: 'Key trends' },
  { path: '/state-of-api/2024/api-global-growth/', label: 'Global growth of APIs' },
  { path: '/state-of-api/2024/who-works-with-apis/', label: 'Who works with APIs' },
  { path: '/state-of-api/2024/api-use-and-discovery/', label: 'API use & discovery' },
  { path: '/state-of-api/2024/api-production/', label: 'API production' },
  { path: '/state-of-api/2024/api-priorities-and-outlook/', label: 'API priorities & outlook' },
  { path: '/state-of-api/2024/api-collaboration/', label: 'API collaboration' },
  { path: '/state-of-api/2024/api-monetization/', label: 'API monetization' },
  { path: '/state-of-api/2024/artificial-intelligence/', label: 'Artificial intelligence' },
  { path: '/state-of-api/2024/api-technologies/', label: 'API technologies' }
];

const PrevNextSection = () => {
  const isBrowser = typeof window !== 'undefined';
  const [currentPage, setCurrentPage] = useState(isBrowser ? window.location.pathname : '');
  const [hasMounted, setHasMounted] = useState(false);
  const formattedPage = currentPage.replace('/state-of-api/2024/', '');
    useEffect(() => {
    if (isBrowser) {
      setCurrentPage(window.location.pathname);
      setHasMounted(true);
  
      const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft') {
          document.getElementById(`prev-btn-${formattedPage}`)?.click();
        } else if (e.key === 'ArrowRight') {
          document.getElementById(`next-btn-${formattedPage}`)?.click();
        }
      };
  
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isBrowser, formattedPage]);

  if (!hasMounted) {
    return null;
  }

  const currentIndex = paths.findIndex(route => withPrefix(route.path) === withPrefix(currentPage));
  const prevPage = currentIndex > 0 ? paths[currentIndex - 1] : null;
  let nextPage = currentIndex < paths.length - 1 ? paths[currentIndex + 1] : null;

  // Special case: if on the last page, set nextPage to the first page
  if (currentPage === '/state-of-api/2024/api-technologies/') {
    nextPage = paths[0];
  }

  const handlePrevClick = (e) => {
    e.preventDefault();
    if (prevPage) {
      const newPath = prevPage.path.startsWith('/state-of-api') ? prevPage.path : withPrefix(prevPage.path);
      window.location.pathname = newPath;
    }
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    if (nextPage) {
      const newPath = nextPage.path.startsWith('/state-of-api') ? nextPage.path : withPrefix(nextPage.path);
      window.location.pathname = newPath;
    }
  };
 
  return (
    <Wrapper>
      <div className="container">
        <GradientLine />
        <div className='row justify-content-between'>
          {prevPage && prevPage.label && (
            <div className='col-lg-4 mb-4 mb-lg-0'>
              <StyledButton onClick={handlePrevClick} href={prevPage.path} id={`prev-btn-${formattedPage}`}>
                <div className='wrapper'>
                  <div className='text-container text-right'>
                    <span className="text-gradient">Previous</span>
                    <div className='d-flex align-items-center justify-content-between'>
                      <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.7071 0.292893C12.0976 0.683418 12.0976 1.31658 11.7071 1.70711L2.41421 11L11.7071 20.2929C12.0976 20.6834 12.0976 21.3166 11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071L0.292892 11.7071C-0.0976316 11.3166 -0.0976316 10.6834 0.292892 10.2929L10.2929 0.292893C10.6834 -0.0976312 11.3166 -0.0976312 11.7071 0.292893Z" fill="#5266E3" />
                      </svg>

                      <p className="h3 ms-auto mb-0">{prevPage ? prevPage.label : ''}</p>
                    </div>
                  </div>
                </div>
              </StyledButton>
            </div>
          )}
          {nextPage && nextPage.label && (
            <div className={`col-lg-4 ${currentPage === '/state-of-api/2024/' ? 'ml-auto' : ''}`}>
              <StyledButton onClick={handleNextClick} href={nextPage.path} id={`next-btn-${formattedPage}`}>
                <div className='wrapper'>
                  <div className='text-container text-left'>
                    <span className="text-gradient">Next</span>
                    <div className='d-flex align-items-center justify-content-between'>
                      <p className="h3 mb-0">{nextPage ? nextPage.label : ''}</p>
                      <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 21.7071C-0.097631 21.3166 -0.0976309 20.6834 0.292893 20.2929L9.58579 11L0.292896 1.70711C-0.0976284 1.31658 -0.0976283 0.683418 0.292896 0.292894C0.68342 -0.0976314 1.31659 -0.0976313 1.70711 0.292895L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071Z" fill="#5266E3" />
                      </svg>
                    </div>
                  </div>
                </div>
              </StyledButton>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

PrevNextSection.propTypes = {
  prevLabel: PropTypes.string,
  nextLabel: PropTypes.string,
};

PrevNextSection.defaultProps = {
  prevLabel: 'Previous',
  nextLabel: 'Next',
};

export default PrevNextSection;