import React from 'react';
import styled from 'styled-components';
import SocialShare from './SocialShare';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */ // root relative link

const StylesWrapper = styled.section`
padding-top: ${(props) => props.paddingTopMobile || '60px'};
padding-bottom: ${(props) => props.paddingBottom || '60px'};
background: ${(props) => props.background === "light" ? '#e2e8ff !important' : ''};
${(props) => props.border && 'border-bottom: 10px solid #FF6C37;'}
@media (min-width: 992px) {
  padding-top: ${(props) => props.paddingTop || '60px'};
}
  h2 {
    font-size: 65px;
    line-height: .7;
  }
  .h3 {
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
  }
  .eyebrow {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    color: ${(props) => props.background === "light" ? '#172144' : 'white'};
  }
  .subtitle {
   color: ${(props) => props.background === "light" ? '#172144' : '#869AE2'};
    font-size: 24px;
    font-weight: 400;
    line-height: 1.25;
    margin-bottom: 10px;
  }
    .subtitle a {
    color: #FF6C37;
    }
    .subtitle a:hover {
    color: #FF6C37;
    border-bottom: 1px solid
    }
.quote-subtitle {
   color: ${(props) => props.background === "light" ? '#172144' : 'white'};
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 10px;
  }
a {
  color: #0265D2;
}
a:hover {
  color: #0265D2;
  border-bottom: 1px solid #0265D2;
  text-decoration: none;
}
.orange {
  color: #FF6C37;
}
  .orange:hover {
  color: #FF6C37;
  border-bottom: 1px solid #FF6C37;
}
  .title-gradient {
    font-size: 55px;
    line-height: 1.1;
    background: ${(props) => props.background === "light" ? '#172144' : 'linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%)'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 10px;
   
  }
     @media (max-width: 899px) {
     .title-gradient, h2 {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 0px;
    }
}
`;

// const StyledLink = styled.a`
//   color: white;
//   text-decoration: none;
//   position: relative;
//   display: inline-flex;
//   align-items: center;
//   padding-bottom: 4px; /* Add padding to make space for the border */
//   transition: color 0.3s ease-in-out;

//   &::before {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 4px;
//     background: #ff6c37; /* Initial orange border */
//     transform: scaleX(1);
//     transform-origin: left;
//     transition: transform 0.3s ease-in-out;
//   }

//   &:hover::before {
//     transform: scaleX(0); /* Hide the orange border on hover */
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     height: 4px;
//     background: linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%);
//     transform: scaleX(0);
//     transform-origin: left;
//     transition: transform 0.3s ease-in-out;
//   }
//   &:hover {
//     color: white;
//     text-decoration: none;
//   }
//   &:hover::after {
//     transform: scaleX(1); /* Show the gradient border on hover */
//   }
//   span {
//     position: relative;
//     right: 0;
//     transition: transform 0.3s ease, opacity 0.3s ease;
//     opacity: 1;
//   }

//   &:hover span {
//     transform: translateX(2px); /* Adjust the value as needed */
//     opacity: 1;
//   }
// `;

const GradientLine = styled.div`
  background: ${(props) => (props.background === 'light' ? '#B9C1F3' : '#5266e3')};
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  margin-top: 60px;
`;

const VerticalLine = styled.div`
  @media (min-width: 992px) {
    position: absolute;
     right: ${(props) => (props.verticalPosition ? props.verticalPosition : '0')};
    top: -17px;
    bottom: 0;
    width: 2px;
    background: ${(props) => (props.background === 'light' ? '#B9C1F3' : '#5266e3')};
    height: 100%;
    display: ${(props) => (props.vertical ? 'block' : 'none')};
  }

  position: relative;
  width: 100%;
  height: 2px;
  background: #5266e3;
  display: ${(props) => (props.vertical ? 'block' : 'none')};
  margin: 30px 0; 
`;
/* eslint-disable react/prop-types */
function SideBySide24({ childPage, eyebrow, alignText, title, subtitle, body, leftclassName, leftContent, leftContentStacked, rightContent, vertical, verticalPosition, extraContent, paddingTop, divider, quoteSection, border, share, background, extraDivider, alignContentText, paddingTopMobile, paddingBottom }) {
  return (
    <StylesWrapper paddingTop={paddingTop} border={border} background={background} paddingTopMobile={paddingTopMobile} paddingBottom={paddingBottom}>
      <div className="container position-relative">
        {title && (
          <div className="row">
            <div className="col-lg-12 mb-4">
              {childPage && <p className="eyebrow mb-lg-2">2024 State of API Report</p>}
              {eyebrow && <p className="eyebrow mb-lg-2 mb-0">{eyebrow}</p>}
              {title && <h2 className="title-gradient ">{title}</h2>}
              {(subtitle || body) && (
                <div className='col-lg-10 pl-0'>
                  {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle }} />}
                  {body && <p className="pl-0">{body}</p>}
                </div>
              )}
            </div>
          </div>
        )}
        <div className={`row ${alignText || ''}`}>
          {leftContent && (
            <div className={`${leftclassName} `}>
              {leftContent.map((item) => (
                <div key={pmUuid()} className={`${item.title || item.body ? `row ${alignContentText || ''}` : ''}`}>
                  {item.image && (
                    <div className={`${item.image.className}`}>
                      <img fetchpriority={item.fetch ? 'high' : 'low'} src={item.image.src} alt={item.image.alt} className="img-fluid mb-4 mb-lg-0" />
                    </div>
                  )}

                  <div className={`${item.textClassName ? item.textClassName : 'col-lg-7'}`}>
                    {item.title && <p className="h3">{item.title}</p>}
                    {item.body && <div dangerouslySetInnerHTML={{ __html: item.body }} />}
                    {item.link && (
                      <StyledLink id={item.link.id} href={item.link.href}>
                        {item.link.text}
                        <span className="arrow">→</span>
                      </StyledLink>
                    )}
                  </div>
                </div>
              ))}

              <VerticalLine vertical={vertical} background={background} verticalPosition={verticalPosition} />

            </div>
          )}
          {leftContentStacked && (
            <div className={`${leftclassName}`}>
              {leftContentStacked.map((stackedItem, index) => (
                <div key={pmUuid()} className={index === 0 ? 'mb-4 mb-lg-0 ' : ''}>
                  {stackedItem.image && <img fetchpriority={stackedItem.fetch ? 'high' : 'low'} src={stackedItem.image.src} alt={stackedItem.image.alt} className={`img-fluid mb-4 ${stackedItem.image.className}`} />}
                  {stackedItem.icon && (
                    <div className="row mb-4">
                      <div className="col-auto d-flex align-items-center ">
                        <img src={stackedItem.icon.src} alt={stackedItem.icon.alt} className="img-fluid" />
                        <p className="h3 ml-2 mb-0">{stackedItem.icon.title}</p>
                      </div>
                    </div>
                  )}
                  <div className="">
                    {stackedItem.title && <p className="h3 ">{stackedItem.title}</p>}
                    {stackedItem.body && <div dangerouslySetInnerHTML={{ __html: stackedItem.body.join('') }} />}
                    {stackedItem.link && (
                      <a id={stackedItem.link.id} href={stackedItem.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                        {stackedItem.link.text}
                      </a>
                    )}
                  </div>
                </div>
              ))}
              <VerticalLine vertical={vertical} background={background} verticalPosition={verticalPosition} />
            </div>
          )}
          <div className={`${rightContent.className}`}>
            <div className="ml-lg-2">
              {rightContent.image && (
                <div className={rightContent.image.className}>
                  <img src={rightContent.image.src} alt={rightContent.image.alt} className="img-fluid mb-4" />
                </div>
              )}
              {rightContent.title && <p className="h3 ">{rightContent.title}</p>}
              {rightContent.body && <div dangerouslySetInnerHTML={{ __html: rightContent.body.join('') }} />}
              {rightContent.link && (
                <a id={rightContent.link.id} href={rightContent.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                  {rightContent.link.text}
                </a>
              )}
              {rightContent.quoteSection && (
                <div className="row ">
                  <div className="col-2">
                    <img src="https://voyager.postman.com/icon/state-of-api/icon-quote.svg" alt="Quote" className="img-fluid" />
                  </div>
                  <div className="col-lg-10">
                    {rightContent.quoteSection.quote && <p className="quote-subtitle">{rightContent.quoteSection.quote}</p>}
                    {rightContent.quoteSection.author && (
                      <p className="mb-lg-0">
                        <strong>{rightContent.quoteSection.author}</strong>, {rightContent.quoteSection.company}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {rightContent.tip && rightContent.tip.map((tip) => (
                <div className="row">
                  <div className=" mb-4 mb-lg-0">
                    <div className='d-flex'>
                      {tip.img && <img src={tip.img.src} alt={tip.img.alt} className="img-fluid mb-4" />}
                      {tip.title && <p className="h3 ml-2 mb-0">{tip.title}</p>}
                    </div>
                    {tip.body && <div dangerouslySetInnerHTML={{ __html: tip.body }} />}

                  </div>
                </div>
              ))}
              {rightContent.stacked &&
                rightContent.stacked.map((stackedItem) => (
                  <div key={pmUuid()} className="">
                    {stackedItem.image && <img fetchpriority={stackedItem.fetch ? 'high' : 'low'} src={stackedItem.image.src} alt={stackedItem.image.alt} className="img-fluid mb-4" />}
                    {stackedItem.icon && (
                      <div className="row align-items-center mb-4">
                        <div className="col-auto d-flex align-items-center ">
                          <img fetchpriority='' src={stackedItem.icon.src} alt={stackedItem.icon.alt} className="img-fluid" />
                          <p className="h3 mb-0 ml-2">{stackedItem.icon.title}</p>
                        </div>
                      </div>
                    )}
                    <div className="">
                      {stackedItem.title && <p className="h3 ">{stackedItem.title}</p>}
                      {stackedItem.body && <div dangerouslySetInnerHTML={{ __html: stackedItem.body.join('') }} />}
                      {stackedItem.link && (
                        <a id={stackedItem.link.id} href={stackedItem.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                          {stackedItem.link.text}
                        </a>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        {divider && (
          <GradientLine background={background} />
        )}
        {extraContent && (
          <>
            {extraContent.icon && (
              <div className="row align-items-center mb-4" style={{ marginTop: '30px' }}>
                <div className="col-auto d-flex align-items-center ">
                  <img src={extraContent.icon.src} alt={extraContent.icon.alt} className="img-fluid" />
                  <p className="h3 ml-2 mb-0">{extraContent.icon.title}</p>
                </div>
              </div>
            )}
            <div className="row mb-4 mb-lg-0">
              <div className="col-lg-8 ">
                <div>
                  {extraContent.image && <img src={extraContent.image.src} alt={extraContent.image.alt} className="img-fluid mb-4" />}
                  {extraContent.title && <p className="h3 ">{extraContent.title}</p>}
                </div>
                {extraContent.body && <div dangerouslySetInnerHTML={{ __html: extraContent.body }} />}
                {extraContent.link && (
                  <a id={extraContent.link.id} href={extraContent.link.href} target="_blank" rel="noreferrer" className="hover-arrow">
                    {extraContent.link.text}
                  </a>
                )}
              </div>
            </div>
          </>
        )}
        {extraDivider && (
          <GradientLine style={{ marginTop: '60px' }} background={background} />
        )}
        {quoteSection && (
          <div className="row mt-4">
            <div className="col-1">
              <img src="https://voyager.postman.com/icon/state-of-api/icon-quote.svg" alt="Quote" className="img-fluid" />
            </div>
            <div className="col-lg-11">
              {quoteSection.quote && <p className="quote-subtitle">{quoteSection.quote}</p>}
              {quoteSection.author && (
                <p className="mb-lg-0">
                  <strong>{quoteSection.author}</strong>, {quoteSection.company}
                </p>
              )}
            </div>
          </div>
        )}
        {share && <SocialShare content={share.content} gradient={true} divider={false} />}
      </div>
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default SideBySide24;
