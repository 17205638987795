import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withPrefix } from 'gatsby';
import DoughnutChart from './DoughnutChart';
import BarChart from './BarChart';
// import ChartKey24 from './ChartKey24';

const ChartContainer = styled.section`
  background-color: #e2e8ff;
 padding-top: ${(props) => props.paddingTop || '60px'};
 padding-bottom: ${(props) => props.paddingBottom || '0px'};
 @media (max-width: 768px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  h2 {
    font-size: 55px;
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      font-size: 35px;
    }
  }
    p {
    color: #172144 !important;
    }
    .subtitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    }
  .chart-subtitle {
    color: #172144;
    font-size: 11px;
    font-weight: 400;
    padding-top: 20px;
    font-style: italic;
  }
a {
  color: #0265D2;
}
a:hover {
  color: #0265D2;
  border-bottom: 1px solid #0265D2;
  text-decoration: none;
}
`;
const GradientLine = styled.div`
  background: #B9C1F3;
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
`;


function Chart24({ chart, divider, image, paddingTop, paddingBottom }) {
  const { chartTitle, chartBody, chartData: data, options, subtitle, isFullWidth, tip } = chart;
  const chartBodyContent = Array.isArray(chartBody) ? chartBody.join('') : chartBody;
  return (
    <ChartContainer paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {isFullWidth ? (
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-4">
              {chartTitle && <h2>{chartTitle}</h2>}
              {chartBodyContent && <div dangerouslySetInnerHTML={{ __html: chartBodyContent }} />}
              {tip && (
                <div className="pt-4">
                  <div className="row align-items-center mb-4">
                    <div className="col-auto d-flex align-items-center ">
                      <img src={withPrefix(tip.img.src)} alt={tip.img.alt} className="img-fluid" />
                      <p className="h3 ml-2 mb-0">{tip.title}</p>
                    </div>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: tip.body.join('') }} />
                </div>
              )}
            </div>
            <div className="col-12">{data.chartType === 'bar' ? <BarChart data={data} options={options} subtitle={subtitle} /> : <DoughnutChart data={data} options={options} subtitle={subtitle}/>}</div>
          </div>
          {divider && (
            <GradientLine style={{ marginTop: '60px' }} />
          )}
        </div>
      ) : (
        <div>
          {chartTitle && <h2>{chartTitle}</h2>}
          {chartBody && <p>{chartBody}</p>}
            {data.chartType === 'bar' ? <BarChart data={data} options={options} subtitle={subtitle}  /> : <DoughnutChart  data={data} options={options} subtitle={subtitle}  />}
        </div>
      )}
      {image && <img src={image} alt="chart" />}
    </ChartContainer>
  );
}

Chart24.propTypes = {
  divider: PropTypes.bool,
  chart: PropTypes.shape({
    chartTitle: PropTypes.string,
    isFullWidth: PropTypes.bool,
    kitchen: PropTypes.bool,
    useDatasetLabels: PropTypes.bool,
    keyside: PropTypes.bool,
    tip: PropTypes.shape({
      img: PropTypes.shape({
        alt: PropTypes.string
      }),
      title: PropTypes.string,
      body: PropTypes.string
    }),
    chartBody: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    chartData: PropTypes.shape({
      chartType: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.string),
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          data: PropTypes.arrayOf(PropTypes.number),
          backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
          borderWidth: PropTypes.number,
          hoverBorderColor: PropTypes.string,
          borderColor: PropTypes.string
        })
      )
    }),
    options: PropTypes.shape({
      indexAxis: PropTypes.string,
      responsive: PropTypes.bool,
      maintainAspectRatio: PropTypes.bool,
      plugins: PropTypes.shape({
        legend: PropTypes.shape({
          display: PropTypes.bool
        }),
        tooltip: PropTypes.shape({
          callbacks: PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
          })
        })
      }),
      scales: PropTypes.shape({
        x: PropTypes.shape({
          display: PropTypes.bool,
          beginAtZero: PropTypes.bool,
          border: PropTypes.shape({
            color: PropTypes.string
          }),
          ticks: PropTypes.shape({
            display: PropTypes.bool,
            color: PropTypes.string,
            lineColor: PropTypes.string,
            callback: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            autoSkip: PropTypes.bool
          }),
          grid: PropTypes.shape({
            drawOnChartArea: PropTypes.bool,
            display: PropTypes.bool,
            color: PropTypes.string,
            offset: PropTypes.bool
          })
        }),
        y: PropTypes.shape({
          display: PropTypes.bool,
          beginAtZero: PropTypes.bool,
          border: PropTypes.shape({
            color: PropTypes.string
          }),
          ticks: PropTypes.shape({
            autoSkip: PropTypes.bool,
            display: PropTypes.bool,
            color: PropTypes.string
          }),
          grid: PropTypes.shape({
            drawOnChartArea: PropTypes.bool,
            display: PropTypes.bool,
            color: PropTypes.string,
            offset: PropTypes.bool
          })
        })
      })
    }),
    subtitle: PropTypes.string
  })
};
Chart24.defaultProps = {
  divider: false,
  chart: {
    chartTitle: null,
    isFullWidth: false,
    kitchen: false,
    useDatasetLabels: false,
    keyside: false,
    tip: null,
    chartBody: null,
    chartData: null,
    options: {},
    subtitle: null
  }
};
export default Chart24;
