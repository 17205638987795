import React from 'react';
import styled from 'styled-components';
import SocialShare from './SocialShare';


const StylesWrapper = styled.section`
  padding-top: ${(props) => props.paddingTop || '60px'};
  padding-bottom: ${(props) => props.paddingBottom || '60px'};
  background: ${(props) => props.background === "light" ? '#e2e8ff !important' : ''};
  ${(props) => props.border && 'border-bottom: 10px solid #FF6C37;'}
  h2 {
    font-size: 55px;
    line-height: 1;
  }
  .h3 {
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
  }
  .eyebrow {
    font-size: 24px;
    font-weight: 600;
  }
  p {
    // color: white;
    color: ${(props) => props.background === "light" ? '#172144' : 'white'};
  }
  .subtitle {
   color: ${(props) => props.background === "light" ? '#172144' : 'white'};
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 10px;
  }
a {
color: #0265D2;
}
a:hover {
color: #0265D2;
border-bottom: 1px solid #0265D2;
text-decoration: none;
}
  .title-gradient {
    font-size: 55px;
    line-height: 1;
    background: ${(props) => props.background === "light" ? '#172144' : 'linear-gradient(90.93deg, #00d7e5 0.8%, #369af3 21.41%, #6764ff 96.62%)'};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 30px;
    @media (max-width: 899px) {
      font-size: 35px;
      line-height: 35px;
      margin-bottom: 30px;
    }
  }
`;

const GradientLine = styled.div`
  background: ${(props) => (props.background === 'light' ? '#B9C1F3' : '#5266e3')};
  background-size: 100% 200px;
  height: 2px;
  width: 100%;
  // margin: 0px 0px 40px 0;
`;

/* eslint-disable react/prop-types */
function TextSection24({ title, subtitle, body, paddingBottom, paddingTop, divider, image, tip, border, share, background }) {
  const shouldRenderSection = title || subtitle || body;

  return (
    <StylesWrapper paddingTop={paddingTop} paddingBottom={paddingBottom} border={border} background={background}>
      <div className="container ">
          {shouldRenderSection && <div className="row">
            <div className="col-lg-10 mb-4">
              {title && <h2 className="">{title}</h2>}
              {subtitle && <p className="subtitle ">{subtitle}</p>}
              {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
            </div>
          </div>
          }
        {image && (
          <div className="row">
            <div className=" mb-4 mb-lg-0">
              <img src={image.src} alt={image.alt}  className="img-fluid"/>
            </div>
          </div>
        )}
        {tip && (
          <div className="pt-4">
           {tip.title && <div className="row align-items-center mb-4">
              <div className="col-auto d-flex align-items-center ">
                <img  src={tip.img.src} alt={tip.img.alt} className="img-fluid" />
                <p className="h3 ml-2 mb-0">{tip.title}</p>
              </div>
            </div>}
            <div dangerouslySetInnerHTML={{ __html: tip.body }} />
          </div>
        )}
        {divider && (
           <GradientLine style={{ marginTop: '60px' }} background={background} />
        )}
        {share && <SocialShare />}
      </div>
    </StylesWrapper>
  );
}
/* eslint-enable react/prop-types */
export default TextSection24;
